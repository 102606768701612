<template>
  <div>
    <div class="w-content">
      <form @submit.prevent="performTransacHandler">
        <h2 class="title">{{$t("Acc_Desc_13")}}</h2>
        <div class="mb-20">{{$t("Acc_Desc_4")}}</div>
        <div class="mb-10">{{$t("Acc_Desc_6")}}</div>
        <select
          :disabled="disabledField"
          class="select mb-10"
          id="Currency"
          @change="onChangeSelect(tokenPublicKey)"
          v-model="tokenPublicKey"
        >
          <option v-for="(item,index) in balanceList" :key="index" :value="item.publicKey">
            <span v-if="item.name==='CS'">Credits (CS)</span>
            <span v-else>{{item.name}} ({{item.alias}})</span>
          </option>
          <!-- TODO select coins from balance-->
        </select>
        <label
          class="d-block mb-10"
        >{{$t("Acc_Desc_7")}}</label>
        <input
          class="main-input"
          id="Address"
          placeholder="Enter Wallet Address here"
          v-model.trim="address"
          :disabled="disabledField"

        />
        <span
          class="textColorRed"
          id="ErrorAddress"
          v-if="$v.address.$dirty && !$v.address.required"
        >{{$t("Acc_input_1_error")}}</span>
        <label class="d-block mb-10">{{$t("Acc_Desc_8")}}</label>
        <input
          :disabled="disabledField"
          class="main-input"
          id="Amount"
          placeholder="0.0"
          v-model="amount"

        />
        <span
          class="textColorRed"
          id="ErrorAmount"
          v-if="$v.amount.$dirty && !$v.amount.required"
        >{{$t("Acc_input_2_error")}}</span>
<!--        <p class="textColorRed" id="ErrorAmount"></p>-->
        <label class="d-block mb-10">{{$t("Acc_Desc_9")}}</label>
        <input
          :disabled="disabledField"
          class="main-input"
          id="Fee"
          placeholder="0.0"
          v-model="fee"
        />

        <div class="hidden" v-if="visible">
          <p class="textColorRed mb-50" id="ErrorFee"></p>
          <label class="d-block mb-10">User data (optional field)</label>
          <input
            :disabled="disabledField"
            class="main-input"
            id="UserData"
            placeholder="Enter you data"
            v-model="userData"
          />
          <p class="textColorRed mb-50" id="ErrorUserData"></p>
          <label class="d-block mb-10">Used smart contracts (optional field)</label>
          <input
            class="main-input"
            id="UsedContracts"
            placeholder="Enter comma - separated public keys for smart contracts"
            v-model="userContracts"
          />
        </div>
        <a class="link" v-on:click="visible=!visible">{{ visible?"Hide additional fields":"Show additional fields" }}</a>
<!--        <p class="textColorRed mb-50" id="ErrorUserData"></p>-->
        <button :disabled="disabledField" type="submit" class="bold-link bttn">{{$t("Acc_button_1")}}</button>
      </form>
    </div>
    <!-- <div class="w-content" v-else>
      <h2 class="title">!!!!!!! MESSAGE ACCOUNT LOGIN</h2>
    </div>-->
  </div>
</template>

<style>
  .link {
    margin: 10px;
  }
</style>

<script>
  import { required } from "vuelidate/lib/validators";
  import { mapState } from "vuex";
  import instance from "../http";
  import {TheMask} from 'vue-the-mask'
  export default {
    name: "perform-transaction",

    data: () => ({
      address: "",
      amount: "",
      fee: 0.1,
      userData: "",
      userContracts: "",
      tokenPublicKey: "",
      responseTransactionPackData: {},
      methodApi: "TransferCs",
      disabledField: false,
      visible: false
    }),
    validations: {
      address: { required },
      amount: { required }
    },
    methods: {
      performTransacHandler() {
        if (this.$v.$invalid === true) {
          this.$v.$touch();
          //console.log("adress is required");
          return;
        }

        let _amount = this.amount.toString().replace(',','.');
        let _fee = this.fee.toString().replace(',','.');

        let requestApiModel = {
          PublicKey: this.publicKey,
          ReceiverPublicKey: this.address,
          TokenPublicKey: this.tokenPublicKey,
          Amount: parseFloat(_amount),
          UserData: this.userData,
          MethodApi: this.methodApi,
          Fee: parseFloat(_fee),
          NetworkAlias: this.networkAlias
        };
        let postContent = JSON.stringify(requestApiModel);
        let headers = {
          "Content-Type": "application/json"
        };
        let _this = this;
        //console.log("requestAPIMODEL:");
        //console.log(requestApiModel);
        new Promise(function(resolver, reject) {
          _this.disabledField = true;
          // _this.$loading(true);
          instance
            .post("../../api/wallet/packtransaction", requestApiModel, { headers })
            .then(request => {
              //console.log("RESPONSE:");
              //console.log(request.data);
              resolver(request);
            })
            .catch(ex => {
              // _this.$loading(false);
              //console.log(ex);
              reject(ex);
            });
        }).then(d => {
          // this.$emit("performTransactionUpdate");
          //console.log(d.data);
          // this.responseTransactionPackData = d.data;
          this.$store.commit("SET_transactionPackData", requestApiModel);
          this.$store.commit("SET_responseTransactionPack", d.data);
          this.$router.push({
            path: "../wallet/perform-transaction-result"
          });
        });
      },
      onChangeSelect(e) {
        this.tokenPublicKey = e;
        if (this.tokenPublicKey === "" || this.tokenPublicKey === null) {
          this.methodApi = "TransferCs";
        } else {
          this.methodApi = "TransferToken";
        }
        console.log(e);
      }
    },
    computed: {
      //getter
      ...mapState({
        publicKey: state => state.publicKey,
        privateKey: state => state.privateKey,
        balanceList: state => state.balanceList,
        networkAlias: s => s.networkAlias
      })
    },
    components:{TheMask}
  };
</script>
