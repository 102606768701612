// import Vue from "vue";
import axios from "axios";


const instance = axios.create({
    baseURL: "/api",
    headers: {
        "Content-Type": "application/json",
    },

});

instance.interceptors.request.use(config => {
    // NProgress.start();
  //this.loading(true);

    return config;
});

instance.interceptors.response.use(response => {
    // NProgress.done();
  //this.loading(false);

    return response;
});

export default instance
